import { AnalyticsSettingSchema, PluginNameEnum } from "src/types"
import { analyticsAxios as axios } from "src/utils/axios"

import { GoldenDataLakeEnum } from "./types"

type AnalyticsBodyType = {
  bucket: GoldenDataLakeEnum
  client: string
  portfolio: string
  plugin: keyof typeof PluginNameEnum
  parameters: typeof AnalyticsSettingSchema
}

export const getSectionData = (body: AnalyticsBodyType) =>
  axios.post<unknown>("/analytics/coordinator", body)
