import {
  ISectionSettingsStore,
  UIActionEnum,
  SectionSettingsActionEnum,
  SectionSettingsActionType,
} from "src/redux/types"

import initialState from "./initialState"

const sectionSettingsReducer = (
  state = initialState,
  action: SectionSettingsActionType
): ISectionSettingsStore => {
  switch (action.type) {
    case SectionSettingsActionEnum.INIT_SECTION_CONFIGS_SELECTED_VALUES:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.sectionId]: {
            ...state.byId[action.payload.sectionId],
            configs: action.payload.data,
          },
        },
      }

    case SectionSettingsActionEnum.ENABLE_SPLIT_AND_COMPARE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.sectionId]: {
            ...state.byId[action.payload.sectionId],
            splitFilterId: action.payload.filterId,
            splitA: [],
            splitB: [],
          },
        },
      }
    }

    case SectionSettingsActionEnum.UPDATE_SPLIT_AND_COMPARE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.sectionId]: {
            ...state.byId[action.payload.sectionId],
            [action.payload.reqType]: action.payload.splitValue,
          },
        },
      }

    case SectionSettingsActionEnum.CLEAR_SPLIT_AND_COMPARE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.sectionId]: {
            ...state.byId[action.payload.sectionId],
            [action.payload.reqType]: [],
          },
        },
      }

    case SectionSettingsActionEnum.DISABLE_SPLIT_AND_COMPARE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.sectionId]: {
            ...state.byId[action.payload.sectionId],
            splitFilterId: undefined,
            splitA: [],
            splitB: [],
          },
        },
      }

    case SectionSettingsActionEnum.UPDATE_SECTION_CONFIGS_SELECTED_VALUES:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.sectionId]: {
            ...state.byId[action.payload.sectionId],
            configs: {
              ...state.byId[action.payload.sectionId]?.configs,
              ...action.payload.data,
            },
          },
        },
      }

    case SectionSettingsActionEnum.UPDATE_SECTION_FILTER_SELECTED_VALUES:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.sectionId]: {
            ...state.byId[action.payload.sectionId],
            filters: action.payload.data,
          },
        },
      }

    case UIActionEnum.CLEAR_STORE:
      return initialState

    default:
      return {
        ...state,
      }
  }
}

export default sectionSettingsReducer
