import { PropType } from "src/types/common"

import {
  UIActionEnum,
  ClearStoreType,
  ToggleExternalSidebarType,
  ToggleInternalSidebarType,
  UpdateSidebarStatusType,
} from "./types"

export const clearStore = (): ClearStoreType => ({
  type: UIActionEnum.CLEAR_STORE,
})

export const toggleExternalSidebar = (): ToggleExternalSidebarType => ({
  type: UIActionEnum.TOGGLE_EXTERNAL_SIDEBAR,
})

export const toggleInternalSidebar = (): ToggleInternalSidebarType => ({
  type: UIActionEnum.TOGGLE_INTERNAL_SIDEBAR,
})

export const updateSidebarStatus = (
  payload: PropType<UpdateSidebarStatusType, "payload">
): UpdateSidebarStatusType => ({
  type: UIActionEnum.UPDATE_SIDEBAR_STATUS,
  payload,
})
