import { AxiosResponse } from "axios"
import { all, call, put, select, takeEvery } from "redux-saga/effects"

import { getSectionData as getSectionDataAPI } from "src/api"
import { SourceColumn } from "src/components/Section/DataQuery/pluginResponseValidation/pluginResponseTypes"
import { SPECIAL_PLUGINS_MAPPING } from "src/data/specialPluginsMapping"
import {
  OptionType,
  AllAnalyticsSettingsType,
  FilterNameEnum,
  PluginNameEnum,
  StockTableItemType,
} from "src/types/common"
import { getDataLake } from "src/utils/helpers/getDataLake"
import { sortStocksAlphabetical } from "src/utils/helpers/sortStocksAlphabetical"

import { getAnalyticsSettingsAllByIdSelector } from "../analytics-settings/selectors"
import {
  getClientSettingsClientIDSelector,
  getClientSettingsPortfolioIDSelector,
} from "../client-settings/selectors"
import { FiltersStoreType, RequestHistoryType } from "../types"

import { fetchSectionDataFailure, fetchSectionDataSuccess } from "./actions"
import { requestsHistorySelector } from "./selectors"
import { FetchSectionDataRequestType, SectionDataActionEnum } from "./types"

const getSectionData = ({
  action,
  clientID,
  portfolioID,
}: {
  action: FetchSectionDataRequestType
  clientID: string
  portfolioID: string
}) => {
  const {
    stateId,
    globalSettings,
    sectionFilters = {},
    sectionConfigs = {},
    injectedParams = {},
  } = action.payload

  const isSectionFiltersEmpty = !Object.keys(sectionFilters).length

  const mapFiltersToBackendValues = (filters: FiltersStoreType) => {
    const newKeys = Object.keys(filters).map(key => key.split("_FILTER")[0])
    const values = Object.values(filters)

    return newKeys.reduce((acc, newKey, i) => {
      return {
        ...acc,
        [newKey]: values[i],
      }
    }, {})
  }

  const body = {
    bucket: getDataLake(),
    client: clientID,
    portfolio: portfolioID,
    plugin: SPECIAL_PLUGINS_MAPPING[stateId] || stateId,
    parameters: {
      ...globalSettings,
      ...sectionConfigs,
      ...(!isSectionFiltersEmpty
        ? { FILTER_BY: mapFiltersToBackendValues(sectionFilters) }
        : {}),
      ...injectedParams,
    },
  }

  return getSectionDataAPI(body)
}

function* fetchSectionDataSaga(action: FetchSectionDataRequestType) {
  try {
    const clientID: string = yield select(getClientSettingsClientIDSelector)
    const portfolioID: string = yield select(
      getClientSettingsPortfolioIDSelector
    )
    const analyticsSettings: AllAnalyticsSettingsType = yield select(
      getAnalyticsSettingsAllByIdSelector
    )

    // This is a temporary workaround - we are fully aware this needs clean up - this is a post processing mechanism before the API request is made to remove inactive filter values from the request, e.g. All
    const actionFilters = Object.keys(action.payload.sectionFilters).reduce<
      Record<FilterNameEnum, string[]>
    >((acc, curr) => {
      const key = curr as FilterNameEnum

      const inactiveOption = analyticsSettings[key]?.data?.options?.find(
        (item: OptionType) => item.inactive
      )?.value

      if (
        inactiveOption &&
        !action.payload.sectionFilters[key]?.includes(inactiveOption)
      ) {
        acc[key] = action.payload.sectionFilters[key] as string[]
      }

      return acc
    }, {} as Record<FilterNameEnum, string[]>)

    // Not include reqType into requestKey to optimize requests and return the same data for standard, splitA, spliB requests with the same params
    const { reqType, ...requestKeyData } = action.payload
    // Create a string with all params, filters, configs for which we have already fetched data
    const requestKey = JSON.stringify({
      ...requestKeyData,
      sectionFilters: actionFilters,
    })

    const requestsHistory: RequestHistoryType = yield select(
      requestsHistorySelector
    )
    const stateIdRequests = requestsHistory[action.payload.stateId]
    let resultDataForStore = stateIdRequests?.[requestKey]

    if (!resultDataForStore) {
      const response: AxiosResponse<unknown> = yield call(getSectionData, {
        action: {
          ...action,
          payload: {
            ...action.payload,
            sectionFilters: actionFilters,
          },
        },
        clientID,
        portfolioID,
      })

      resultDataForStore = response.data
    }

    yield put(
      fetchSectionDataSuccess({
        stateId: action.payload.stateId,
        reqType: action.payload.reqType,
        data:
          action.payload.stateId === PluginNameEnum.STOCK_TABLE
            ? sortStocksAlphabetical(
                resultDataForStore as {
                  result: {
                    sourceColumns: Array<SourceColumn>
                    sourceData: Array<StockTableItemType>
                  }
                }
              )
            : resultDataForStore,
        requestKey,
      })
    )
  } catch (e) {
    yield put(
      fetchSectionDataFailure({
        stateId: action.payload.stateId,
        reqType: action.payload.reqType,
        error: (e as Error).message,
      })
    )
  }
}

/**
 * Starts worker saga on every dispatched `FETCH_SECTION_DATA_REQUEST` action.
 * Allows concurrent updates
 */
function* sectionDataSaga() {
  yield all([
    takeEvery(
      SectionDataActionEnum.FETCH_SECTION_DATA_REQUEST,
      fetchSectionDataSaga
    ),
  ])
}

export default sectionDataSaga
