import {
  IClientSettingsStore,
  ClientSettingsActionEnum,
  ClientSettingsActionType,
  UIActionEnum,
} from "src/redux/types"

import initialState from "./initialState"

const clientPortfolioSettingsReducer = (
  state = initialState,
  action: ClientSettingsActionType
): IClientSettingsStore => {
  switch (action.type) {
    case ClientSettingsActionEnum.FETCH_CLIENTS_REQUEST:
      return {
        ...state,
        pending: true,
      }

    case ClientSettingsActionEnum.FETCH_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: action.payload.data,
        portfolios: state.clientID
          ? action.payload?.data?.find(
              client => client.id === Number(state.clientID)
            )?.portfolios || []
          : [],
        pending: false,
      }
    }

    case ClientSettingsActionEnum.FETCH_CLIENTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        pending: false,
      }

    case ClientSettingsActionEnum.UPDATE_ACTIVE_PORTFOLIO:
      return {
        ...state,
        clientID: action.payload.clientID,
        portfolioID: action.payload.portfolioID,
      }

    case UIActionEnum.CLEAR_STORE:
      return initialState

    default:
      return state
  }
}

export default clientPortfolioSettingsReducer
