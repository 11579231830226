import {
  CustomizationTypesEnum,
  CustomizationType,
  PluginNameEnum,
  IdType,
} from "src/types/common"

import { ClearStoreType } from "../UI/types"

export enum CustomizationsActionEnum {
  FETCH_CUSTOMIZATION_FAILURE = "FETCH_CUSTOMIZATION_FAILURE",
  FETCH_CUSTOMIZATION_REQUEST = "FETCH_CUSTOMIZATION_REQUEST",
  FETCH_CUSTOMIZATION_SUCCESS = "FETCH_CUSTOMIZATION_SUCCESS",
  UPDATE_CUSTOMIZATION_IN_STORE = "UPDATE_CUSTOMIZATION_IN_STORE",
}

export type CustomizationsActionType =
  | FetchCustomizationFailureType
  | FetchCustomizationRequestType
  | FetchCustomizationSuccessType
  | UpdateCustomizationInStoreType
  | ClearStoreType

export type FetchCustomizationFailureType = {
  type: CustomizationsActionEnum.FETCH_CUSTOMIZATION_FAILURE
  payload: { error: string }
}

export type FetchCustomizationRequestType = {
  type: CustomizationsActionEnum.FETCH_CUSTOMIZATION_REQUEST
  payload: {
    stateId: PluginNameEnum
    portfolioId: IdType
  }
}

export type FetchCustomizationSuccessType = {
  type: CustomizationsActionEnum.FETCH_CUSTOMIZATION_SUCCESS
  payload: { data: CustomizationType[] }
}

export type UpdateCustomizationInStoreType = {
  type: CustomizationsActionEnum.UPDATE_CUSTOMIZATION_IN_STORE
  payload: {
    type: CustomizationTypesEnum
    updatedData: CustomizationType
  }
}
