import * as React from "react"

import {
  SelectOptionType,
  ScatterPlotDataItemType,
  TimingGraphDataPoint,
  VerticalBarDataItemType as VerticalBarDataItemTypeLib,
} from "@behaviour-lab/blab-component-library"

export type AnalyticsSettingEnum =
  | ConfigNameEnum
  | ParamNameEnum
  | FilterNameEnum

export const AnalyticsSettingSchema: ParameterSchemaType = {
  // Standard Parameters
  DATE_FORMAT: "string",
  RAMP_LENGTH: "number",
  BUY_SELECTION_PERFORMANCE_THRESHOLD: "number",
  BUY_TIMING_PERFORMANCE_THRESHOLD: "number",
  SELL_TIMING_PERFORMANCE_THRESHOLD: "number",
  BUY_SELECTION_HORIZON: "number",
  BUY_TIMING_HORIZON: "number",
  SELL_TIMING_HORIZON: "number",
  HOLDING_PERFORMANCE_HORIZON: "number",
  MAX_FLOW_PARAMETER: "number",
  MINIMUM_WEIGHT_CHANGE: "number",
  UNDERWATER_DEPTH: "number",
  SMOOTHER: "number",
  UNDERWATER_LIMIT: "number",
  FALLING_KNIFE_DROP: "number",
  FK_LOOKBACK_WINDOW: "number",
  BIG_P_DROP: "number",
  VOLATILITY_LOOKBACK_WINDOW: "number",
  ASSET_FILTER: "object",
  PERFORMANCE_OR_IMPACT: "string",
  ROUND_TRIP_LOOK_FORWARD_LENGTH: "number",
  ROUND_TRIP_LENGTH: "number",
  ROUND_TRIP_THRESHOLD: "number",
  ROUND_TRIP_LOSS_THRESHOLD: "number",
  SELECTED_ASSET_ID: "string",
  // Configs
  BENCHMARK_SEDOL: "string",
  DATE_PERIOD: "object",
  // Filters
  BUCKET_FILTER: "object",
  COUNTRY_FILTER: "object",
  FUND_MANAGER_FILTER: "object",
  SECTOR_FILTER: "object",
  STYLE_FILTER: "object",
  THEME_FILTER: "object",
}

/**
 * Union type for all the posible settings including Configs, Filters, and Parameters
 */
export type AppliedSettingsType = {
  [index: string]: string | number | object | boolean | Array<any>
}

export enum ConfigNameEnum {
  BENCHMARK_SEDOL = "BENCHMARK_SEDOL",
  DATE_PERIOD = "DATE_PERIOD",
}

/**
 * Mapping of section data identifier to plugin name on the backend
 * LHS is the identifier of the section data
 * RHS is the plugin name on the backend
 *
 * Choosing this approach for now because
 * 1. It's quickest to implement
 * 2. - hold that thought
 *
 * Note: part of this implementation is stupid - we use different
 * enum types for the same plugin to get around the fact that plugins
 * are re-used. This is an artifact of trying to finish far too much
 * work in too little time and for whoever is reading this I am sorry.
 * - Yannis + Ruben, July 2022
 */
export enum FilterNameEnum {
  BUCKET_FILTER = "BUCKET_FILTER",
  COUNTRY_FILTER = "COUNTRY_FILTER",
  FUND_MANAGER_FILTER = "FUND_MANAGER_FILTER",
  SECTOR_FILTER = "SECTOR_FILTER",
  STYLE_FILTER = "STYLE_FILTER",
  THEME_FILTER = "THEME_FILTER",
}

export type PossibleParamTypes =
  | string
  | number
  | boolean
  | { [key: string]: string[] }
  | { dateFrom: string; dateTo: string }
  | undefined
  | null

export enum ParamNameEnum {
  DATE_FORMAT = "DATE_FORMAT",
  RAMP_LENGTH = "RAMP_LENGTH",
  BUY_SELECTION_PERFORMANCE_THRESHOLD = "BUY_SELECTION_PERFORMANCE_THRESHOLD",
  BUY_TIMING_PERFORMANCE_THRESHOLD = "BUY_TIMING_PERFORMANCE_THRESHOLD",
  SELL_TIMING_PERFORMANCE_THRESHOLD = "SELL_TIMING_PERFORMANCE_THRESHOLD",
  BUY_SELECTION_HORIZON = "BUY_SELECTION_HORIZON",
  BUY_TIMING_HORIZON = "BUY_TIMING_HORIZON",
  SELL_TIMING_HORIZON = "SELL_TIMING_HORIZON",
  HOLDING_PERFORMANCE_HORIZON = "HOLDING_PERFORMANCE_HORIZON",
  MAX_FLOW_PARAMETER = "MAX_FLOW_PARAMETER",
  MINIMUM_WEIGHT_CHANGE = "MINIMUM_WEIGHT_CHANGE",
  UNDERWATER_DEPTH = "UNDERWATER_DEPTH",
  SMOOTHER = "SMOOTHER",
  UNDERWATER_LIMIT = "UNDERWATER_LIMIT",
  FALLING_KNIFE_DROP = "FALLING_KNIFE_DROP",
  FK_LOOKBACK_WINDOW = "FK_LOOKBACK_WINDOW",
  BIG_P_DROP = "BIG_P_DROP",
  VOLATILITY_LOOKBACK_WINDOW = "VOLATILITY_LOOKBACK_WINDOW",
  ASSET_FILTER = "ASSET_FILTER",
  PERFORMANCE_OR_IMPACT = "PERFORMANCE_OR_IMPACT",
  ROUND_TRIP_LOOK_FORWARD_LENGTH = "ROUND_TRIP_LOOK_FORWARD_LENGTH",
  ROUND_TRIP_LENGTH = "ROUND_TRIP_LENGTH",
  ROUND_TRIP_THRESHOLD = "ROUND_TRIP_THRESHOLD",
  ROUND_TRIP_LOSS_THRESHOLD = "ROUND_TRIP_LOSS_THRESHOLD",
  SELECTED_ASSET_ID = "SELECTED_ASSET_ID",
}

export type ParameterSchemaType = Partial<
  Record<
    keyof typeof ParamNameEnum | FilterNameEnum | ConfigNameEnum,
    PossibleParamTypes
  >
>

export enum PluginNameEnum {
  ////////// Everywhere //////////
  // Top right of every page
  O_FUND_PERFORMANCE_HORIZONTAL_WATERFALL = "O_FUND_PERFORMANCE_HORIZONTAL_WATERFALL",
  FUND_OVERALL_PERFORMANCE_LINE_AND_IMPACT = "FUND_OVERALL_PERFORMANCE_LINE_AND_IMPACT",

  // Overview Page
  O_BEST_WORST_AREA_CARDS = "O_BEST_WORST_AREA_CARDS",

  ////////// Stock & Stocks Pages //////////
  // Stocks Page
  STOCK_TABLE = "STOCK_TABLE",
  // Stock Page
  STOCK_GRAPH = "STOCK_GRAPH",
  SP_STOCK_HISTORY_TABLE = "SP_STOCK_HISTORY_TABLE",
  SP_STOCK_TABLE_MULTI_STAT_CARD = "SP_STOCK_TABLE_MULTI_STAT_CARD",
  SP_BUY_SELL_SELECTION_TIMING_MULTI_STAT_CARD = "SP_BUY_SELL_SELECTION_TIMING_MULTI_STAT_CARD",

  ////////// Buying - Buy Selection //////////
  BBS_BUY_SELECTION_IMPACT = "BBS_BUY_SELECTION_IMPACT",
  BBS_BUY_SELECTION_IMPACT_DISTRIBUTION = "BBS_BUY_SELECTION_IMPACT_DISTRIBUTION",
  BBS_BUY_SELECTION_IMPACT_PERFORMANCE = "BBS_BUY_SELECTION_IMPACT_PERFORMANCE",
  BBS_BUY_SELECTION_IMPACT_BY_CATEGORY = "BBS_BUY_SELECTION_IMPACT_BY_CATEGORY",
  // Area Card - Buy Selection Area Card
  BBS_AREA_CARD = "BBS_AREA_CARD",
  // Section - Buy Selection Performance
  BBS_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH = "BBS_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH",
  BBS_PERFORMANCE_COLOUR_CODED_TABLE = "BBS_PERFORMANCE_COLOUR_CODED_TABLE",
  BBS_PERFORMANCE_VERTICAL_BAR_GRAPH_WITH_TRENDLINE = "BBS_PERFORMANCE_VERTICAL_BAR_GRAPH_WITH_TRENDLINE",
  BBS_PERFORMANCE_HIT_RATE_WIN_LOSS_STOCK_TABLE = "BBS_PERFORMANCE_HIT_RATE_WIN_LOSS_STOCK_TABLE",

  BBS_PERFORMANCE_STOCK_TABLE = "BBS_PERFORMANCE_STOCK_TABLE",
  BBS_PERFORMANCE_STOCK_TABLE_DISTRIBUTION = "BBS_PERFORMANCE_STOCK_TABLE_DISTRIBUTION",
  BBS_PERFORMANCE_STOCK_TABLE_HIT_RATE = "BBS_PERFORMANCE_STOCK_TABLE_HIT_RATE",
  BBS_PERFORMANCE_STOCK_TABLE_SELECTION_OVER_TIME = "BBS_PERFORMANCE_STOCK_TABLE_SELECTION_OVER_TIME",
  BBS_PERFORMANCE_STOCK_TABLE_WIN_LOSS = "BBS_PERFORMANCE_STOCK_TABLE_WIN_LOSS",
  // Section - Buy Selection Distribution
  BBS_DISTRIBUTION_SCATTER_PLOT = "BBS_DISTRIBUTION_SCATTER_PLOT", // Note: Interaction is supposed to redirect to Stock Page
  BBS_DISTRIBUTION_SELECTION_PERFORMANCE_TABLE = "BBS_DISTRIBUTION_SELECTION_PERFORMANCE_TABLE",
  // Section - Buy Selection By Category
  BBS_HORIZONTAL_STACKED_BAR_GRAPH = "BBS_HORIZONTAL_STACKED_BAR_GRAPH",
  // Section - Buy Selection by Category
  BBS_CATEGORY_PERFORMANCE_TABLE = "BBS_CATEGORY_PERFORMANCE_TABLE",
  BBS_CATEGORY_STOCK_TABLE = "BBS_CATEGORY_STOCK_TABLE",
  ////////// Selling - Sell Timing //////////
  SST_SELL_TIMING_IMPACT = "SST_SELL_TIMING_IMPACT",
  SST_SELL_TIMING_IMPACT_PERFORMANCE = "SST_SELL_TIMING_IMPACT_PERFORMANCE",
  SST_SELL_TIMING_IMPACT_BY_CATEGORY = "SST_SELL_TIMING_IMPACT_BY_CATEGORY",
  // Area Cards
  SST_SOLD_BOTTOM_AREA_CARD = "SST_SOLD_BOTTOM_AREA_CARD",
  SST_SOLD_EARLY_AREA_CARD = "SST_SOLD_EARLY_AREA_CARD",
  SST_SOLD_GREAT_AREA_CARD = "SST_SOLD_GREAT_AREA_CARD",
  SST_SOLD_LATE_AREA_CARD = "SST_SOLD_LATE_AREA_CARD",
  // Section - Sell Timing Performance
  SST_DISTRIBUTION_TIMING_GRAPH = "SST_DISTRIBUTION_TIMING_GRAPH",
  SST_PERFORMANCE_COLOUR_CODED_TABLE_DEFAULT = "SST_PERFORMANCE_COLOUR_CODED_TABLE_DEFAULT",
  SST_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH = "SST_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH",
  SST_PERFORMANCE_STOCK_TABLE = "SST_PERFORMANCE_STOCK_TABLE",
  SST_COLOUR_CODED_TABLE = "SST_COLOUR_CODED_TABLE",
  // Section - Sell Timing By Category
  SST_CATEGORY_STOCK_TABLE = "SST_CATEGORY_STOCK_TABLE", // Note: Interaction with Bar Graph provides parameters to show in tertiary panel
  SST_HORIZONTAL_STACKED_BAR_GRAPH = "SST_HORIZONTAL_STACKED_BAR_GRAPH",
  SST_CATEGORY_PERFORMANCE_TABLE = "SST_CATEGORY_PERFORMANCE_TABLE",

  SST_DRAW_TOTAL_IMPACT = "SST_DRAW_TOTAL_IMPACT",
  SST_SELL_SNAIL_TRAIL_TOTAL_IMPACT = "SST_SELL_SNAIL_TRAIL_TOTAL_IMPACT",
  // Sell Timing - Draw Down Draw Up
  SST_DRAW_DOWN_FROM_PEAK_GROUPING_TABLE = "SST_DRAW_DOWN_FROM_PEAK_GROUPING_TABLE",
  SST_DRAW_DOWN_FROM_PEAK_SCATTER = "SST_DRAW_DOWN_FROM_PEAK_SCATTER",
  SST_DRAW_DOWN_FROM_PEAK_VERTICAL_STACKED_BAR = "SST_DRAW_DOWN_FROM_PEAK_VERTICAL_STACKED_BAR",
  SST_DRAW_UP_FROM_TROUGH_GROUPING_TABLE = "SST_DRAW_UP_FROM_TROUGH_GROUPING_TABLE",
  SST_DRAW_UP_FROM_TROUGH_SCATTER = "SST_DRAW_UP_FROM_TROUGH_SCATTER",
  SST_DRAW_DOWN_DRAW_UP_STOCK_TABLE = "SST_DRAW_DOWN_DRAW_UP_STOCK_TABLE",
  SST_DRAW_UP_FROM_TROUGH_VERTICAL_STACKED_BAR = "SST_DRAW_UP_FROM_TROUGH_VERTICAL_STACKED_BAR",

  ////////// Value Creation Over Time //////////
  // Multi Stat Card - Value Creation Over Time
  VCOT_MULTI_STAT_CARD = "VCOT_MULTI_STAT_CARD",
  // Section - Holding Period Analysis
  VCOT_HOLDING_PERIOD_INTERACTION_TABLE = "VCOT_HOLDING_PERIOD_INTERACTION_TABLE",
  VCOT_HOLDING_PERIOD_TOTAL_IMPACT = "VCOT_HOLDING_PERIOD_TOTAL_IMPACT",
  VCOT_HOLDING_PERIOD_SNAIL_TRAIL_AGGREGATE = "VCOT_HOLDING_PERIOD_SNAIL_TRAIL_AGGREGATE",
  VCOT_HOLDING_PERIOD_SNAIL_TRAIL_BREAKDOWN = "VCOT_HOLDING_PERIOD_SNAIL_TRAIL_BREAKDOWN",
  VCOT_HOLDING_PERIOD_SCATTER_PLOT = "VCOT_HOLDING_PERIOD_SCATTER_PLOT",
  VCOT_HOLDING_PERIOD_TABLE = "VCOT_HOLDING_PERIOD_TABLE",

  ////////// Weighting //////////
  // Area Cards
  W_INITIAL_WEIGHTING_AREA_CARD = "W_INITIAL_WEIGHTING_AREA_CARD",
  W_ADD_TRIM_AREA_CARD = "W_ADD_TRIM_AREA_CARD",
  // Section - Weighting of Adds and Trims
  W_ADD_TRIM_TOTAL_IMPACT = "W_ADD_TRIM_TOTAL_IMPACT",
  W_ADD_TRIM_EVENTS_BAR_CHART = "W_ADD_TRIM_EVENTS_BAR_CHART",
  W_ADD_TRIM_EVENTS_TABLE = "W_ADD_TRIM_EVENTS_TABLE",
  W_ADD_TRIM_EVENTS_INTERACTION_TABLE = "W_ADD_TRIM_EVENTS_INTERACTION_TABLE",
  W_ADD_TRIM_TABLE_WITH_ICONS_ADD = "W_ADD_TRIM_TABLE_WITH_ICONS_ADD",
  W_ADD_TRIM_TABLE_WITH_ICONS_TRIM = "W_ADD_TRIM_TABLE_WITH_ICONS_TRIM",
  W_ADD_TRIM_EVENT_GRAPH_ADD = "W_ADD_TRIM_EVENT_GRAPH_ADD",
  W_ADD_TRIM_EVENT_GRAPH_TRIM = "W_ADD_TRIM_EVENT_GRAPH_TRIM",
  // Section - Initial Weighting
  W_INITIAL_TOTAL_IMPACT = "W_INITIAL_TOTAL_IMPACT",
  W_INITIAL_SCATTER_PLOT = "W_INITIAL_SCATTER_PLOT",
  W_INITIAL_SIMPLE_TABLE = "W_INITIAL_SIMPLE_TABLE",
  W_INITIAL_INTERACTION_STOCK_TABLE = "W_INITIAL_INTERACTION_STOCK_TABLE",
  // Section - Weighting Across the Journey
  W_JOURNEY_TOTAL_IMPACT = "W_JOURNEY_TOTAL_IMPACT",
  W_JOURNEY_SCATTER_PLOT = "W_JOURNEY_SCATTER_PLOT",
  W_JOURNEY_SIMPLE_TABLE = "W_JOURNEY_SIMPLE_TABLE",
  W_JOURNEY_INTERACTION_STOCK_TABLE = "W_JOURNEY_INTERACTION_STOCK_TABLE",

  ////////// Portfolio Construction //////////
  // Portfolio Construction - Performance
  PC_CATEGORY_EXPOSURE_OVER_TIME_BAR_CHART = "PC_CATEGORY_EXPOSURE_OVER_TIME_BAR_CHART",

  PC_CATEGORY_EXPOSURE_TABLE = "PC_CATEGORY_EXPOSURE_TABLE",
  PC_CATEGORY_EXPOSURE_TABLE_SELECTION = "PC_CATEGORY_EXPOSURE_TABLE_SELECTION",
  PC_CATEGORY_EXPOSURE_TABLE_ALLOCATION = "PC_CATEGORY_EXPOSURE_TABLE_ALLOCATION",

  PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE = "PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE",
  PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_SELECTION = "PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_SELECTION",
  PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_ALLOCATION = "PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_ALLOCATION",

  PC_CATEGORY_EXPOSURE_YEAR_TABLE = "PC_CATEGORY_EXPOSURE_YEAR_TABLE",
  PC_PERFORMANCE_LINE_GRAPH = "PC_PERFORMANCE_LINE_GRAPH",
  PC_PERFORMANCE_TOTAL_IMPACT = "PC_PERFORMANCE_TOTAL_IMPACT",
  PC_PERFORMANCE_RISK_ADJUSTED_LINE_GRAPH = "PC_PERFORMANCE_RISK_ADJUSTED_LINE_GRAPH",
  // Portfolio Construction - Risk
  PC_RISK_TOTAL_IMPACT = "PC_RISK_TOTAL_IMPACT",
  PC_RISK_PORTFOLIO_CORRELATION_LINE_GRAPH = "PC_RISK_PORTFOLIO_CORRELATION_LINE_GRAPH",
  PC_RISK_VOLATILITY_LINE_GRAPH = "PC_RISK_VOLATILITY_LINE_GRAPH",
  PC_RISK_BENCHMARK_CORRELATION_LINE_GRAPH = "PC_RISK_BENCHMARK_CORRELATION_LINE_GRAPH",
  // Section - Allocation
  PC_RETURN_ATTRIBUTION_TOTAL_IMPACT = "PC_RETURN_ATTRIBUTION_TOTAL_IMPACT",
  PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_SELECTION = "PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_SELECTION",
  PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_ALLOCATION = "PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_ALLOCATION",

  PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART = "PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART",
  PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_SELECTION = "PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_SELECTION",
  PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_ALLOCATION = "PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_ALLOCATION",

  PC_RETURN_ATTRIBUTION_TABLE = "PC_RETURN_ATTRIBUTION_TABLE",
  PC_RETURN_ATTRIBUTION_TABLE_SELECTION = "PC_RETURN_ATTRIBUTION_TABLE_SELECTION",
  PC_RETURN_ATTRIBUTION_TABLE_ALLOCATION = "PC_RETURN_ATTRIBUTION_TABLE_ALLOCATION",

  PC_CATEGORY_EXPOSURE_YEAR_TABLE_SELECTION = "PC_CATEGORY_EXPOSURE_YEAR_TABLE_SELECTION",
  PC_CATEGORY_EXPOSURE_YEAR_TABLE_ALLOCATION = "PC_CATEGORY_EXPOSURE_YEAR_TABLE_ALLOCATION",

  ////////// Special Situations - Area Cards //////////
  SSUW_UNDERWATER_AREA_CARD = "SSUW_UNDERWATER_AREA_CARD",
  SSRT_ROUNDTRIP_AREA_CARD = "SSRT_ROUNDTRIP_AREA_CARD",
}

export enum SectionNameEnum {
  ////////// Overview Page (3) //////////
  O_FUND_PERFORMANCE = "O_FUND_PERFORMANCE",
  ////////// Portfolio Construction Page (4) //////////
  PC_EXPOSURE_OVER_TIME = "PC_EXPOSURE_OVER_TIME",
  PC_PERFORMANCE = "PC_PERFORMANCE",
  PC_RISK = "PC_RISK",
  PC_SELECTION = "PC_SELECTION",
  PC_ALLOCATION = "PC_ALLOCATION",

  ////////// Weighting Page (5) //////////
  W_ADDS_AND_TRIMS_AREA_CARD = "W_ADDS_AND_TRIMS_AREA_CARD",
  W_WEIGHTING_OF_ADDS_AND_TRIMS = "W_WEIGHTING_OF_ADDS_AND_TRIMS",
  W_INITIAL_WEIGHTING = "W_INITIAL_WEIGHTING",
  W_WEIGHTING_ACROSS_THE_JOURNEY = "W_WEIGHTING_ACROSS_THE_JOURNEY",

  ////////// Buying - Buy Selection Page (4) //////////
  BBS_SELECTION_AREA_CARD = "BBS_SELECTION_AREA_CARD",
  BBS_BUY_SELECTION_PERFORMANCE = "BBS_BUY_SELECTION_PERFORMANCE",
  BBS_BUY_SELECTION_DISTRIBUTION = "BBS_BUY_SELECTION_DISTRIBUTION",
  BBS_SELECTION_BY_CATEGORY = "BBS_SELECTION_BY_CATEGORY",

  ////////// Buying - Buy Timing Page (6) //////////
  BBT_BOUGHT_LATE_AREA_CARD = "BBT_BOUGHT_LATE_AREA_CARD",
  BBT_BOUGHT_EARLY_AREA_CARD = "BBT_BOUGHT_EARLY_AREA_CARD",
  BBT_BOUGHT_AT_TOP_AREA_CARD = "BBT_BOUGHT_AT_TOP_AREA_CARD",
  BBT_BUY_TIMING_AREA_CARD = "BBT_BUY_TIMING_AREA_CARD",
  BBT_BUY_TIMING_PERFORMANCE = "BBT_BUY_TIMING_PERFORMANCE",
  BBT_BUY_TIMING_BY_CATEGORY = "BBT_BUY_TIMING_BY_CATEGORY",

  ////////// Selling - Sell Timing (6) //////////
  SST_SOLD_BOTTOM_AREA_CARD = "SST_SOLD_BOTTOM_AREA_CARD",
  SST_SOLD_EARLY_AREA_CARD = "SST_SOLD_EARLY_AREA_CARD",
  SST_SOLD_GREAT_AREA_CARD = "SST_SOLD_GREAT_AREA_CARD",
  SST_SOLD_LATE_AREA_CARD = "SST_SOLD_LATE_AREA_CARD",
  SST_SELL_TIMING_PERFORMANCE = "SST_SELL_TIMING_PERFORMANCE",
  SST_SELL_TIMING_BY_CATEGORY = "SST_SELL_TIMING_BY_CATEGORY",
  SST_NEW_SELL_TIMING = "SST_NEW_SELL_TIMING",
  SST_SELL_SNAIL = "SST_SELL_SNAIL",
  SST_PEAKS_AND_TROUGHS = "SST_PEAKS_AND_TROUGHS",

  ////////// Value Creation Over Time Page (3) //////////
  VCOT_MULTI_STAT_CARD = "VCOT_MULTI_STAT_CARD",
  VCOT_HOLDING_PERIOD_ANALYSIS = "VCOT_HOLDING_PERIOD_ANALYSIS",

  ////////// Special Situations - Overview //////////
  SSO_FALLING_KNIVES_AREA_CARD = "SSO_FALLING_KNIVES_AREA_CARD",
  SSO_ROUND_TRIPS_AREA_CARD = "SSO_ROUND_TRIPS_AREA_CARD",
  SSO_UNDERWATER_AREA_CARD = "SSO_UNDERWATER_AREA_CARD",

  ////////// Special Situations - Falling Knives //////////
  SSFK_FALLING_KNIVES_AREA_CARD = "SSFK_FALLING_KNIVES_AREA_CARD",

  ////////// Special Situations - Round Trips //////////
  SSRT_ROUND_TRIPS_AREA_CARD = "SSRT_ROUND_TRIPS_AREA_CARD",

  ////////// Special Situations - Underwater //////////
  SSU_UNDERWATER_AREA_CARD = "SSU_UNDERWATER_AREA_CARD",

  ////////// Stocks Page & Stock Page(s) (2) //////////
  STOCK_TABLE = "STOCK_TABLE",
  STOCK_GRAPH = "STOCK_GRAPH",
  SP_STOCK_HISTORY_TABLE = "SP_STOCK_HISTORY_TABLE",
  SP_STOCK_TABLE_MULTI_STAT_CARD = "SP_STOCK_TABLE_MULTI_STAT_CARD",
  SP_BUY_SELL_SELECTION_TIMING_MULTI_STAT_CARD = "SP_BUY_SELL_SELECTION_TIMING_MULTI_STAT_CARD",
}

export type SettingNameType = ConfigNameEnum | FilterNameEnum | ParamNameEnum

// These are param values that must be sent to the backend as -1 insted of their normal values
export enum SpecialParamValues {
  UNTIL_SELL = "Until Sell",
}

export enum SplitRequestEnum {
  SPLIT_A = "splitA",
  SPLIT_B = "splitB",
  STANDARD = "standard",
}

export enum AddsTrimsEnum {
  ADDS = "ADDS",
  TRIMS = "TRIMS",
}

export type AnalyticsSettingsValueType = {
  id: AnalyticsSettingEnum
  title?: string
  description?: string
  selected?: SelectedAnalyticsSettingType
  data: SettingType | null
  lowerBound?: string
  upperBound?: string
}

export type AllAnalyticsSettingsType = {
  [key in AnalyticsSettingEnum]?: AnalyticsSettingsValueType
}

export enum AllocationOrSelectionEnum {
  ALLOCATION = "ALLOCATION",
  SELECTION = "SELECTION",
}

export type ApiClientPayload = {
  id?: IdType
  name: string
  alias: string
  users: { id?: IdType; email: string; role: RolesEnum }[]
}

export type ApiPortfolioPayload = {
  name: string
  fileName: string
  client: { id: IdType }
  authorizedUsers?: { id: IdType }[]
  categories?: { id?: IdType; name: string; type: PortfolioCategoryEnum }[]
}

export enum AxisFormatEnum {
  PERCENTAGE = "PERCENTAGE",
  DATE = "DATE",
  TRIM = "TRIM",
  NUMBER = "NUMBER",
  MONTHS = "MONTHS",
}

export type ClientFormType = {
  id?: IdType
  name: string
  alias: string
  users?: ClientFormUserType[]
}

export type ClientFormUserType = {
  id?: IdType
  email: string
  role: {
    label: string
    value: RolesEnum
  }
}

export type ClientType = {
  id: IdType
  name: string
  portfolios: PortfolioType[]
  alias: string
  users: UserType[]
  createdAt: string
  deletedAt: string | null
  updatedAt: string
}

export type CommonCustomizationType = {
  id: IdType
  type: CustomizationTypesEnum
  value: unknown
  portfolioId?: IdType
  userId: IdType
}

export type CustomizationType =
  | StockTableColumnsCustomizationType
  | CommonCustomizationType

export enum CustomizationTypesEnum {
  STOCK_TABLE_COLUMNS = "STOCK_TABLE_COLUMNS",
}

export type DataItemType = {
  [key: string]:
    | string
    | number
    | {
        data?: {
          [key: string]: string | number
        }
      }
}

export enum DrawDownOrDrawUpEnum {
  SST_DRAW_DOWN_FROM_PEAK = "SST_DRAW_DOWN_FROM_PEAK",
  SST_DRAW_UP_FROM_TROUGH = "SST_DRAW_UP_FROM_TROUGH",
}

export enum HitRateOrWinLossEnum {
  HIT_RATE_OVER_TIME = "HIT_RATE_OVER_TIME",
  WIN_LOSS_OVER_TIME = "WIN_LOSS_OVER_TIME",
}

export type IdType = number

export type KeyMetricItemType =
  | PluginNameEnum
  | {
      stateId: PluginNameEnum
      injectedParams?: InjectedParamsType
    }

export type NavigationLinkType = {
  label: string
  to: string
  Icon: (arg: { className?: string }) => React.ReactElement
}

export type OptionType = {
  id: IdType
  value?: string
  displayValue?: string
  default?: boolean
  inactive?: boolean
  settingId: number
  selectedBy?: UserType[]
  createdAt?: string
  deletedAt?: string | null
  updatedAt?: string
}

export enum PerformanceOrImpactEnum {
  PERFORMANCE = "PERFORMANCE",
  IMPACT = "IMPACT",
}

export enum PortfolioCategoryEnum {
  BUCKET = "BUCKET",
  STYLE = "STYLE",
  PAYOFF = "PAYOFF",
}

export type PortfolioCategoryType = {
  id: IdType
  type: PortfolioCategoryEnum
  name: string
  portfolioId: IdType
}

export type PortfolioFormType = {
  id?: IdType
  name: string
  fileName: string
  fileUpload?: Partial<File> | null
  client: { label?: string; value: IdType } | null
  authorizedUsers?: SelectOptionType[]
  buckets?: SelectOptionType[]
  bucketOptions?: SelectOptionType[]
  styles?: SelectOptionType[]
  styleOptions?: SelectOptionType[]
  payoffs?: SelectOptionType[]
  payoffOptions?: SelectOptionType[]
}

export enum PortfolioOrActiveWeightEnum {
  PORTFOLIO_WEIGHT = "PORTFOLIO_WEIGHT",
  ACTIVE_WEIGHT = "ACTIVE_WEIGHT",
}

export type PortfolioType = {
  id: IdType
  clientId: IdType
  name: string
  fileName: string
  fileUpload?: File
  uploadUrl?: string
  authorizedUsers?: UserType[]
  categories?: PortfolioCategoryType[]
  createdAt?: string
  updatedAt?: string
  deletedAt?: string | null
}

enum NumericPrecisionTypeEnum {
  INTEGER = "INTEGER",
  DECIMAL = "DECIMAL",
}

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp]

export enum RolesEnum {
  ADMIN = "ADMIN",
  SADMIN = "SADMIN",
  ADVISORY = "ADVISORY",
  USER = "USER",
}

export type SectionType = {
  id: IdType
  name?: string
  utilName?: string
  responseType?: string
  type?: string
  settings?: SettingType[]
}

export type SelectedAnalyticsSettingType =
  | string
  | {
      dateFrom: string
      dateTo: string
    }

export enum SelectionOrAllocationTabEnum {
  TAB_1_IMPACT = "TAB_1_IMPACT",
  TAB_2_IMPACT = "TAB_2_IMPACT",
}

// Passed as params to the plugins
export enum SelectionOrAllocationTabValuesEnum {
  SELECTION_IMPACT = "SELECTION_IMPACT",
  INTERACTION_IMPACT = "INTERACTION_IMPACT",
  ALLOCATION_IMPACT = "ALLOCATION_IMPACT",
  PASSIVE_IMPACT = "PASSIVE_IMPACT",
}

export type SettingType = {
  id: IdType
  name: string | FilterNameEnum
  displayName?: string
  valueType?: SettingValueTypeEnum
  useType?: SettingUseTypeEnum
  options?: OptionType[]
  defaultValue?: string
  defaultSecondaryValue?: string
  lowerBound?: string
  upperBound?: string
  unit?: SettingUnitType
  precision?: NumericPrecisionTypeEnum
  portfolio?: PortfolioType
  portfolioId?: IdType
  section?: SectionType
  sectionId?: IdType
  createdAt?: string
  deletedAt?: string | null
  updatedAt?: string
}

export enum SettingUnitType {
  PERCENTAGE = "PERCENTAGE",
  BPS = "BPS",
  DAYS = "DAYS",
  MONTHS = "MONTHS",
  YEARS = "YEARS",
}

export enum SettingUseTypeEnum {
  PARAM = "PARAM",
  FILTER = "FILTER",
  CONFIG = "CONFIG",
  TOGGLE = "TOGGLE",
}

export enum SettingValueTypeEnum {
  SINGLE_SELECT = "SINGLE_SELECT",
  MULTI_SELECT = "MULTI_SELECT",
  NUMERIC = "NUMERIC",
  DATE = "DATE",
  DATE_RANGE = "DATE_RANGE",
}

export enum SkewEnum {
  HeavyLeftSkew = "HeavyLeftSkew",
  LeftSkew = "LeftSkew",
  CenterSkew = "CenterSkew",
  RightSkew = "RightSkew",
  HeavyRightSkew = "HeavyRightSkew",
}

export type StockTableColumnsCustomizationType = {
  id: IdType
  type: CustomizationTypesEnum.STOCK_TABLE_COLUMNS
  portfolioId: IdType | null
  userId: IdType | null
  value: string[] | null
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export type TopLevelNavigationLinkType = {
  label: string
  to: string
  Icon: (arg: { className?: string }) => React.ReactElement
  subMenuItems?: NavigationLinkType[]
}

export type UserType = {
  id: IdType
  clientId: IdType
  email: string
  role: RolesEnum
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export enum KeyMetricFormatEnum {
  STRING = "STRING",
  PERCENTAGE = "PERCENTAGE",
  NUMBER = "NUMBER",
  TRIM = "TRIM",
}

export type KeyMetricValueType = string | number

export enum KeyMetricTitleEnum {
  HitRate = "Hit Rate",
  WinLossRate = "Win Loss Rate",
  SuboptimalImpact = "Suboptimal Impact",
  BuySelectionImpact = "Buy Selection Impact",
  InvestmentHitRate = "Investment Hit Rate",
  InvestmentWinLossRate = "Investment Win Loss Rate",
  AddsAndTrimsImpact = "Adds and Trims Impact",
  Adds = "Adds",
  Trims = "Trims",
}

export type ValueOf<T> = T[keyof T]

export interface ScatterPlotDataItem extends ScatterPlotDataItemType {
  name?: string
  sedol?: string
  Sedol?: string
  factsetTicker?: string
  filled?: boolean
  monthsPostBuy?: string
  buyDate?: string
  sellDate?: string
}

export type HorizontalWaterfallItemType = {
  value: string | number | null
  label: string
}

export type InjectedParamsType = {
  [paramName: string]: string | number | null
}

export type CurrentAnalyticsSettingsType = {
  [key in AnalyticsSettingEnum]?:
    | number
    | string
    | boolean
    | SelectedAnalyticsSettingType
}

export type TimingGraphDataPointType = TimingGraphDataPoint & {
  factsetTicker?: string
  buyDate?: string
  sellDate?: string
}

export type VerticalBarDataItemType = VerticalBarDataItemTypeLib & {
  year?: string
}

export type StockTableItemType = {
  name: string
  Sedol: string
  [key: string]: string | number | null | number[]
}

export type BreadCrumbType = {
  label: string
  href: string
  active: boolean
}
