import { ApiClientPayload, ClientType, IdType } from "src/types"
import { crudAxios as axios } from "src/utils/axios"

import { ResponseWrapper } from "./types"

export const createClient = (payload: ApiClientPayload) =>
  axios.post<ResponseWrapper<ClientType>>("/client", payload)

export const deleteClient = (clientID: IdType) =>
  axios.delete<ResponseWrapper<string>>(`/client/${clientID}`)

export const getClients = () =>
  axios.get<ResponseWrapper<ClientType[]>>("/client")

export const updateClient = (clientID: IdType, payload: ApiClientPayload) =>
  axios.put<ResponseWrapper<ClientType>>(`/client/${clientID}`, payload)
