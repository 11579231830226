import createSagaMiddleware from "@redux-saga/core"
import { configureStore } from "@reduxjs/toolkit"
import Redux from "redux"
import logger from "redux-logger"

import { rootReducer } from "./rootReducer"
import { rootSaga } from "./rootSaga"

type MiddlewareType = Redux.Middleware<
  Record<string, never>,
  any,
  Redux.Dispatch<Redux.AnyAction>
>[]

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware()

// Enable redux logger if we are in the development or staging environment
const middleware: MiddlewareType = [sagaMiddleware]

if (process.env.TARGET_ENG !== "production") {
  middleware.push(logger)
}

// Mount the saga middleware on the store
const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  devTools: process.env.TARGET_ENG !== "production",
})

// Run the saga
sagaMiddleware.run(rootSaga)

export default store
