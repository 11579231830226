import { groupBy } from "@behaviour-lab/blab-component-library"

import {
  IAnalyticsSettingsStore,
  UIActionEnum,
  AnalyticsSettingsActionType,
  AnalyticsSettingsActionEnum,
} from "src/redux/types"
import {
  SettingType,
  AllAnalyticsSettingsType,
  AnalyticsSettingEnum,
} from "src/types/common"
import getSelectedAnalyticsSettingByDefault from "src/utils/helpers/getSelectedAnalyticsSettingByDefault"

import initialState from "./initialState"

const analyticsSettingsReducer = (
  state = initialState,
  action: AnalyticsSettingsActionType
): IAnalyticsSettingsStore => {
  switch (action.type) {
    case AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_REQUEST:
      return {
        ...state,
        pending: true,
      }

    case AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_SUCCESS: {
      const settingsGroupedByName = groupBy<SettingType>(
        action.payload.data,
        (setting: SettingType) => setting.name
      )

      return {
        ...state,
        pending: false,
        error: null,
        byId: state.allIds.reduce(
          (acc: AllAnalyticsSettingsType, curr: AnalyticsSettingEnum) => {
            if (
              !Object.prototype.hasOwnProperty.call(settingsGroupedByName, curr)
            )
              return acc

            acc[curr] = {
              ...state.byId[curr],
              id: curr,
              data: settingsGroupedByName[curr][0],
              selected: getSelectedAnalyticsSettingByDefault(
                settingsGroupedByName[curr]?.[0]
              ),
            }

            return acc
          },
          {}
        ),
      }
    }

    case AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        byId: initialState.byId,
      }

    case AnalyticsSettingsActionEnum.UPDATE_ANALYTICS_SETTING_SELECTED_VALUE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.stateId]: {
            ...state.byId[action.payload.stateId],
            selected: action.payload.selected,
          },
        },
      }

    case AnalyticsSettingsActionEnum.RESET_ANALYTICS_SETTINGS_TO_DEFAULTS:
      return {
        ...state,
        byId: state.allIds.reduce(
          (acc: AllAnalyticsSettingsType, curr: AnalyticsSettingEnum) => {
            acc[curr] = {
              ...state.byId[curr],
              id: curr,
              data: state.byId[curr]?.data || null,
              selected: state.byId[curr]?.data?.options?.filter(
                item => item.default === true
              )[0].value,
            }

            return acc
          },
          {}
        ),
      }

    case UIActionEnum.CLEAR_STORE:
      return initialState

    default:
      return state
  }
}

export default analyticsSettingsReducer
