import { PluginNameEnum } from "src/types/common"

export const SPECIAL_PLUGINS_MAPPING: {
  [plugin in PluginNameEnum]?: PluginNameEnum
} = {
  [PluginNameEnum.PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_ALLOCATION]:
    PluginNameEnum.PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART,
  [PluginNameEnum.PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_SELECTION]:
    PluginNameEnum.PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART,
  [PluginNameEnum.PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_ALLOCATION]:
    PluginNameEnum.PC_RETURN_ATTRIBUTION_TOTAL_IMPACT,
  [PluginNameEnum.PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_SELECTION]:
    PluginNameEnum.PC_RETURN_ATTRIBUTION_TOTAL_IMPACT,
  [PluginNameEnum.PC_RETURN_ATTRIBUTION_TABLE_ALLOCATION]:
    PluginNameEnum.PC_RETURN_ATTRIBUTION_TABLE,
  [PluginNameEnum.PC_RETURN_ATTRIBUTION_TABLE_SELECTION]:
    PluginNameEnum.PC_RETURN_ATTRIBUTION_TABLE,
  [PluginNameEnum.PC_CATEGORY_EXPOSURE_YEAR_TABLE_ALLOCATION]:
    PluginNameEnum.PC_CATEGORY_EXPOSURE_YEAR_TABLE,
  [PluginNameEnum.PC_CATEGORY_EXPOSURE_YEAR_TABLE_SELECTION]:
    PluginNameEnum.PC_CATEGORY_EXPOSURE_YEAR_TABLE,
  [PluginNameEnum.BBS_BUY_SELECTION_IMPACT_DISTRIBUTION]:
    PluginNameEnum.BBS_BUY_SELECTION_IMPACT,
  [PluginNameEnum.BBS_BUY_SELECTION_IMPACT_PERFORMANCE]:
    PluginNameEnum.BBS_BUY_SELECTION_IMPACT,
  [PluginNameEnum.BBS_BUY_SELECTION_IMPACT_BY_CATEGORY]:
    PluginNameEnum.BBS_BUY_SELECTION_IMPACT,
  [PluginNameEnum.SST_SELL_TIMING_IMPACT_PERFORMANCE]:
    PluginNameEnum.SST_SELL_TIMING_IMPACT,
  [PluginNameEnum.SST_SELL_TIMING_IMPACT_BY_CATEGORY]:
    PluginNameEnum.SST_SELL_TIMING_IMPACT,
  [PluginNameEnum.BBS_PERFORMANCE_STOCK_TABLE_DISTRIBUTION]:
    PluginNameEnum.BBS_PERFORMANCE_STOCK_TABLE,
  [PluginNameEnum.BBS_PERFORMANCE_STOCK_TABLE_HIT_RATE]:
    PluginNameEnum.BBS_PERFORMANCE_STOCK_TABLE,
  [PluginNameEnum.BBS_PERFORMANCE_STOCK_TABLE_SELECTION_OVER_TIME]:
    PluginNameEnum.BBS_PERFORMANCE_STOCK_TABLE,
  [PluginNameEnum.BBS_PERFORMANCE_STOCK_TABLE_WIN_LOSS]:
    PluginNameEnum.BBS_PERFORMANCE_STOCK_TABLE,
  [PluginNameEnum.PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_SELECTION]:
    PluginNameEnum.PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE,
  [PluginNameEnum.PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_ALLOCATION]:
    PluginNameEnum.PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE,
  [PluginNameEnum.PC_CATEGORY_EXPOSURE_TABLE_ALLOCATION]:
    PluginNameEnum.PC_CATEGORY_EXPOSURE_TABLE,
  [PluginNameEnum.PC_CATEGORY_EXPOSURE_TABLE_SELECTION]:
    PluginNameEnum.PC_CATEGORY_EXPOSURE_TABLE,
}
