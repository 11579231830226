import * as React from "react"

import { Amplify } from "@aws-amplify/core"
import { Authenticator } from "@aws-amplify/ui-react"
import {
  AuthProvider,
  AuthComponents,
  formFields,
} from "@behaviour-lab/blab-component-library"
import { LazyMotion, domMax } from "framer-motion"
import { AppProps } from "next/app"
import { useRouter } from "next/router"
import { Toaster } from "react-hot-toast"
import { Provider, useDispatch } from "react-redux"

import "@aws-amplify/ui-react/styles.css"
import "@behaviour-lab/blab-component-library/dist/style.css"
import "src/styles/globals.css"

import store from "src/redux"
import { clearStore } from "src/redux/UI/actions"

Amplify.configure({
  ssr: true,
  Auth: {
    mandatorySignIn: true,
    region: "eu-west-1",
    userPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
    oauth: {
      domain: "blab",
    },
    cookieStorage: {
      domain:
        process.env.NODE_ENV === "development"
          ? "localhost"
          : ".behaviourlab.com",
      secure: process.env.NODE_ENV !== "development",
      path: "/",
      // NOTE: Units is in days: 0.25 = 6 hours
      expires: 0.25,
    },
  },
})

/**
 * This is a custom component that is used to initialize pages.
 * It can be overwritten and control the page initialisation to
 * - persist layout between page changes
 * - keep state when navigating pages
 * - implement custom error handling
 * - inject additional data into pages
 * - add global CSS
 * */
function App(props: AppProps) {
  return (
    <LazyMotion features={domMax}>
      <Provider store={store}>
        <InsideReduxProvider {...props} />
      </Provider>
    </LazyMotion>
  )
}

const InsideReduxProvider = ({ pageProps, Component }: AppProps) => {
  const dispatch = useDispatch()
  const router = useRouter()

  const handleClear = () => {
    dispatch(clearStore())
    router.push("/")
  }

  return (
    <Authenticator
      components={AuthComponents}
      formFields={formFields}
      variation="default"
      hideSignUp={true}
    >
      <AuthProvider onLogout={handleClear}>
        <Component {...pageProps} />
        <Toaster position="top-right" />
      </AuthProvider>
    </Authenticator>
  )
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
// App.getInitialProps = async appContext => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext)

//   return { ...appProps }
// }
export default App
