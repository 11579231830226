import {
  PluginNameEnum,
  SplitRequestEnum,
  CurrentAnalyticsSettingsType,
  FilterNameEnum,
  ConfigNameEnum,
  PropType,
  InjectedParamsType,
  PossibleParamTypes,
} from "src/types/common"

import { ClearStoreType } from "../UI/types"

export type FetchSectionDataFailureType = {
  type: SectionDataActionEnum.FETCH_SECTION_DATA_FAILURE
  payload: {
    error: string
    stateId: PluginNameEnum
    reqType: SplitRequestEnum
  }
}

export type FetchSectionDataRequestType = {
  type: SectionDataActionEnum.FETCH_SECTION_DATA_REQUEST
  payload: {
    stateId: PluginNameEnum
    globalSettings: CurrentAnalyticsSettingsType
    sectionConfigs: {
      [setting in ConfigNameEnum]?: PossibleParamTypes
    }
    sectionFilters: {
      [filterName in FilterNameEnum]?: string[]
    }
    injectedParams?: InjectedParamsType
    reqType: SplitRequestEnum
  }
}

export type FetchSectionDataSuccessType = {
  type: SectionDataActionEnum.FETCH_SECTION_DATA_SUCCESS
  payload: {
    stateId: PluginNameEnum
    reqType: SplitRequestEnum
    data: unknown
    requestKey: string
  }
}

export type IsSectionDataFetchEnabledType = {
  type: SectionDataActionEnum.IS_SECTION_DATA_FETCH_ENABLED
  payload: boolean
}

export type PluginResult = {
  data: any
  pending: boolean
  error: null | string
}

export type PluginResultData = PropType<PluginResult, "data">

export enum SectionDataActionEnum {
  FETCH_SECTION_DATA_FAILURE = "FETCH_SECTION_DATA_FAILURE",
  FETCH_SECTION_DATA_REQUEST = "FETCH_SECTION_DATA_REQUEST",
  FETCH_SECTION_DATA_SUCCESS = "FETCH_SECTION_DATA_SUCCESS",
  IS_SECTION_DATA_FETCH_ENABLED = "IS_SECTION_DATA_FETCH_ENABLED",
}

export type SectionDataActionType =
  | FetchSectionDataFailureType
  | FetchSectionDataRequestType
  | FetchSectionDataSuccessType
  | IsSectionDataFetchEnabledType
  | ClearStoreType

export type SectionDataByIdType = {
  [pluginName in PluginNameEnum]: {
    standard: PluginResult
    splitA: PluginResult
    splitB: PluginResult
  }
}
