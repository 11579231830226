import { AxiosResponse } from "axios"

import { PortfolioType, IdType, ApiPortfolioPayload } from "src/types"
import { crudAxios, s3Axios } from "src/utils/axios"

import { ResponseWrapper } from "./types"

export const createPortfolio = (payload: ApiPortfolioPayload) =>
  crudAxios.post<ResponseWrapper<PortfolioType>>("/portfolio", payload)

export const deletePortfolio = (portfolioID: IdType) =>
  crudAxios.delete(`/portfolio/${portfolioID}`)

export const deletePortfoliosBulk = (payload: number[]) =>
  crudAxios.post<ResponseWrapper<{ deleted: boolean }>>(`/portfolio/delete`, {
    portfolios: payload,
  })

export const getPortfolio = (clientID?: string | IdType) =>
  crudAxios.get<ResponseWrapper<PortfolioType[]>>(
    `/portfolio${clientID ? `?clientId=${clientID}` : ""}`
  )

export const updatePortfolio = (
  portfolioID: IdType,
  payload: ApiPortfolioPayload
) =>
  crudAxios.put<AxiosResponse<PortfolioType>>(
    `/portfolio/${portfolioID}`,
    payload
  )

export const uploadPortfolioFile = (uploadUrl: string, file: File) => {
  const options = {
    headers: {
      "Content-Type": file.type,
    },
  }

  return s3Axios.put<ResponseWrapper<PortfolioType>>(uploadUrl, file, options)
}
