import { SettingType, IdType } from "src/types"
import { crudAxios as axios } from "src/utils/axios"

import { ResponseWrapper } from "./types"

export const createOrUpdateSettingsBulk = (payload: SettingType[]) =>
  axios.post<
    ResponseWrapper<
      {
        setting: SettingType
        success: boolean
      }[]
    >
  >("/setting/bulk", payload)

export const getAnalyticsSettings = (portfolioID: IdType) =>
  axios.get<ResponseWrapper<SettingType[]>>(`/setting?portfolio=${portfolioID}`)
