import { IAnalyticsSettingsStore } from "src/redux/types"
import {
  AllAnalyticsSettingsType,
  ConfigNameEnum,
  ParamNameEnum,
  FilterNameEnum,
  AnalyticsSettingEnum,
} from "src/types/common"

const analyticsSettings = {
  ...ConfigNameEnum,
  ...ParamNameEnum,
  ...FilterNameEnum,
}

const initialState: IAnalyticsSettingsStore = {
  pending: true,
  error: null,
  byId: Object.values(analyticsSettings).reduce(
    (acc: AllAnalyticsSettingsType, curr: AnalyticsSettingEnum) => {
      acc[curr] = {
        id: analyticsSettings[curr],
        data: null,
        selected: undefined,
      }

      return acc
    },
    {}
  ),
  allIds: Object.values(analyticsSettings),
}

export default initialState
