import { PropType } from "src/types/common"

import {
  SectionDataActionEnum,
  FetchSectionDataFailureType,
  IsSectionDataFetchEnabledType,
  FetchSectionDataRequestType,
  FetchSectionDataSuccessType,
} from "./types"

export const fetchSectionDataFailure = (
  payload: PropType<FetchSectionDataFailureType, "payload">
): FetchSectionDataFailureType => ({
  type: SectionDataActionEnum.FETCH_SECTION_DATA_FAILURE,
  payload,
})

export const fetchSectionDataRequest = (
  payload: PropType<FetchSectionDataRequestType, "payload">
): FetchSectionDataRequestType => ({
  type: SectionDataActionEnum.FETCH_SECTION_DATA_REQUEST,
  payload,
})

export const fetchSectionDataSuccess = (
  payload: PropType<FetchSectionDataSuccessType, "payload">
): FetchSectionDataSuccessType => ({
  type: SectionDataActionEnum.FETCH_SECTION_DATA_SUCCESS,
  payload,
})

export const setIsSectionDataFetchEnabled = (
  payload: PropType<IsSectionDataFetchEnabledType, "payload">
): IsSectionDataFetchEnabledType => ({
  type: SectionDataActionEnum.IS_SECTION_DATA_FETCH_ENABLED,
  payload,
})
