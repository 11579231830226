import { createSelector } from "reselect"

import { PortfolioType } from "src/types"

import { AppState } from "../rootReducer"

export const getClientSettingsClientIDSelector = createSelector(
  (state: AppState) => state.clientSettings.clientID,
  clientID => clientID
)

export const getClientSettingsClientsErrorSelector = createSelector(
  (state: AppState) => state.clientSettings.error,
  error => error
)

export const getClientSettingsClientsPendingSelector = createSelector(
  (state: AppState) => state.clientSettings.pending,
  pending => pending
)

export const getClientSettingsClientsSelector = createSelector(
  (state: AppState) => state.clientSettings.clients,
  clients => clients || []
)

export const getClientSettingsPortfolioIDSelector = createSelector(
  (state: AppState) => state.clientSettings.portfolioID,
  portfolioID => portfolioID
)

export const getClientSettingsPortfoliosSelector = createSelector(
  (state: AppState) => state.clientSettings.portfolios,
  portfolios => portfolios
)

export const getPortfolioDataByIdSelector = () =>
  createSelector(
    [
      (state: AppState) => state.clientSettings.portfolios,
      (_state: AppState, portfolioId: string | null) => portfolioId,
    ],
    (portfolios: PortfolioType[], id: string | null) =>
      !id ? undefined : portfolios.find(p => +p.id === +id)
  )
