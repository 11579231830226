import axios, { AxiosError, InternalAxiosRequestConfig } from "axios"

import { addAuthorizationHeader, retryWrapper } from "./helpers"

const crudAxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CRUD_API,
})

crudAxiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    // eslint-disable-next-line no-param-reassign
    config = await addAuthorizationHeader(config)

    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

retryWrapper(crudAxiosInstance, {
  retryTimes: 0,
})

export default crudAxiosInstance
