import { combineReducers } from "redux"

import analyticsSettingsReducer from "./analytics-settings/reducer"
import clientSettingsReducer from "./client-settings/reducer"
import customizationsReducer from "./customizations/reducer"
import SectionDataReducer from "./section-data/reducer"
import sectionSettingsReducer from "./section-settings/reducer"
import UIReducer from "./UI/reducer"

/**
 * `rootReducer` is a combination of all the reducers present in Polygon Client
 */
export type AppState = ReturnType<typeof rootReducer>

export const rootReducer = combineReducers({
  clientSettings: clientSettingsReducer,
  analyticsSettings: analyticsSettingsReducer,
  sectionSettings: sectionSettingsReducer,
  sectionData: SectionDataReducer,
  customizations: customizationsReducer,
  UI: UIReducer,
})
