import { AxiosResponse } from "axios"
import { all, call, put, takeEvery, take, race } from "redux-saga/effects"

import { ResponseWrapper, getClients } from "src/api"
import { fetchAnalyticsSettingsRequest } from "src/redux/analytics-settings/actions"
import { ClientType } from "src/types"

import { AnalyticsSettingsActionEnum } from "../analytics-settings/types"
import { setIsSectionDataFetchEnabled } from "../section-data/actions"

import {
  fetchClientsFailure,
  fetchClientsRequest,
  fetchClientsSuccess,
} from "./actions"
import {
  ClientSettingsActionEnum,
  FetchDataForInternalPagesType,
} from "./types"

/**
 * Worker Saga: Fired on `FETCH_CLIENTS_REQUEST` action
 */
function* fetchClientsSaga() {
  try {
    const response: AxiosResponse<ResponseWrapper<ClientType[]>> = yield call(
      getClients
    )

    yield put(fetchClientsSuccess(response.data))
  } catch (e) {
    yield put(
      fetchClientsFailure({
        error: (e as Error).message,
      })
    )
  }
}

function* fetchDataForInternalPages(action: FetchDataForInternalPagesType) {
  yield put(fetchClientsRequest())

  const { clientFetchError } = yield race({
    clientFetchSuccess: take(ClientSettingsActionEnum.FETCH_CLIENTS_SUCCESS),
    clientFetchError: take(ClientSettingsActionEnum.FETCH_CLIENTS_FAILURE),
  })

  // Do not try to fetch the analytics settings unless the client data have been fetched
  if (clientFetchError) return

  yield put(
    fetchAnalyticsSettingsRequest({ portfolioID: action.payload.portfolioID })
  )

  const { analyticsSettingsFetchError } = yield race({
    success: take(AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_SUCCESS),
    analyticsSettingsFetchError: take(
      AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_FAILURE
    ),
  })

  // Do not try to fetch section data unless analytics settings have been fetched
  if (analyticsSettingsFetchError) return

  yield put(setIsSectionDataFetchEnabled(true))
}

/**
 * Starts worker saga on every dispatched `FETCH_CLIENTS_REQUEST` action.
 * Allows concurrent updates
 */
function* clientPortfolioSettingsSaga() {
  yield all([
    takeEvery(ClientSettingsActionEnum.FETCH_CLIENTS_REQUEST, fetchClientsSaga),
    takeEvery(
      ClientSettingsActionEnum.FETCH_DATA_FOR_INTERNAL_PAGES,
      fetchDataForInternalPages
    ),
  ])
}

export default clientPortfolioSettingsSaga
