import { createSelector } from "reselect"

import {
  SettingUseTypeEnum,
  OptionType,
  AllAnalyticsSettingsType,
  SelectedAnalyticsSettingType,
  AnalyticsSettingSchema,
  ParamNameEnum,
  AnalyticsSettingEnum,
} from "src/types/common"

import { AppState } from "../rootReducer"

/**
 * Selector to retrieve all settings by id in the analytics-settings
 */
export const getAnalyticsSettingsAllByIdSelector = createSelector(
  (state: AppState) => state.analyticsSettings.byId,
  byId => byId
)

/**
 * Selector to retrieve the error state of the analytics settings slice
 */
export const getAnalyticsSettingsErrorSelector = createSelector(
  (state: AppState) => state.analyticsSettings.error,
  error => error
)

/**
 * Selector to retrieve the pending state of the analytics settings slice
 */
export const getAnalyticsSettingsPendingSelector = createSelector(
  (state: AppState) => state.analyticsSettings.pending,
  pending => pending
)

/**
 * Selector to retrieve pre-processed analytics settings for API request (extract selected values)
 */
export const getCurrentAnalyticsSettingsSelector = createSelector(
  [getAnalyticsSettingsAllByIdSelector],
  (analyticsSettings: AllAnalyticsSettingsType) =>
    [...(Object.keys(analyticsSettings) as AnalyticsSettingEnum[])].reduce(
      (
        acc: {
          [key in AnalyticsSettingEnum]?:
            | number
            | string
            | boolean
            | SelectedAnalyticsSettingType
        },
        key: AnalyticsSettingEnum
      ) => {
        const setting = analyticsSettings[key]

        if (
          setting?.data?.useType !== SettingUseTypeEnum.FILTER &&
          typeof setting?.selected !== "undefined" &&
          /* This is a quick workaround to exclude PERFORMANCE_OR_IMPACT from the request for the 11th of Jan deadline.
           PERFORMANCE_OR_IMPACT should be passed only in certain visualisations as injected params, not here as a globals.
           TODO: Remove this when PERFORMANCE_OR_IMPACT has been removed in the CRUD API, as well as the FE enums and schemas
        */
          setting.id !== ParamNameEnum.PERFORMANCE_OR_IMPACT
        ) {
          switch (AnalyticsSettingSchema[setting.id]) {
            case "number":
              acc[key] = Number(setting.selected)
              break

            case "boolean":
              acc[key] = !!setting.selected
              break

            case "object":
            case "array":
            case "string":
            default:
              acc[key] = setting.selected
              break
          }
        }

        return acc
      },
      {}
    )
)

export const getStockNameBySedolSelector = () =>
  createSelector(
    [
      (state: AppState) =>
        state.analyticsSettings.byId[ParamNameEnum.SELECTED_ASSET_ID]?.data
          ?.options,
      (_state: AppState, sedol: string) => sedol,
    ],
    (options: OptionType[] | undefined, sedol: string) =>
      options?.find(o => o.value === sedol)?.displayValue
  )
