import { AxiosResponse } from "axios"
import { all, call, put, takeEvery } from "redux-saga/effects"

import { ResponseWrapper, getStockTableColumnsCustomization } from "src/api"
import { CustomizationType } from "src/types"

import { fetchCustomizationError, fetchCustomizationSuccess } from "./actions"
import {
  CustomizationsActionEnum,
  FetchCustomizationRequestType,
} from "./types"

function* fetchCustomizationsWorker(action: FetchCustomizationRequestType) {
  try {
    const response: AxiosResponse<ResponseWrapper<CustomizationType[]>> =
      yield call(getStockTableColumnsCustomization, action.payload.portfolioId)

    yield put(fetchCustomizationSuccess(response.data))
  } catch (e) {
    yield put(
      fetchCustomizationError({
        error: (e as Error).message,
      })
    )
  }
}

/**
 * Starts worker saga on every dispatched `FETCH_CUSTOMIZATION_REQUEST` action.
 * Allows concurrent updates
 */
function* customizationsSaga() {
  yield all([
    takeEvery(
      CustomizationsActionEnum.FETCH_CUSTOMIZATION_REQUEST,
      fetchCustomizationsWorker
    ),
  ])
}

export default customizationsSaga
