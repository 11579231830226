import { all, fork } from "redux-saga/effects"

import analyticsSettingsSaga from "./analytics-settings/sagas"
import clientSettingsSaga from "./client-settings/sagas"
import customizationsSaga from "./customizations/sagas"
import sectionDataSaga from "./section-data/sagas"

// rootSaga is a combination of all the sagas present in Polygon Client
export function* rootSaga() {
  yield all([
    fork(clientSettingsSaga),
    fork(analyticsSettingsSaga),
    fork(sectionDataSaga),
    fork(customizationsSaga),
  ])
}
