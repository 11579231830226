import {
  SelectedAnalyticsSettingType,
  SettingType,
  AnalyticsSettingEnum,
  IdType,
} from "src/types/common"

import { ClearStoreType } from "../UI/types"

export enum AnalyticsSettingsActionEnum {
  FETCH_ANALYTICS_SETTINGS_REQUEST = "FETCH_ANALYTICS_SETTINGS_REQUEST",
  FETCH_ANALYTICS_SETTINGS_SUCCESS = "FETCH_ANALYTICS_SETTINGS_SUCCESS",
  FETCH_ANALYTICS_SETTINGS_FAILURE = "FETCH_ANALYTICS_SETTINGS_FAILURE",
  RESET_ANALYTICS_SETTINGS_TO_DEFAULTS = "RESET_ANALYTICS_SETTINGS_TO_DEFAULTS",
  UPDATE_ANALYTICS_SETTING_SELECTED_VALUE = "UPDATE_ANALYTICS_SETTING_SELECTED_VALUE",
}

export type AnalyticsSettingsActionType =
  | FetchAnalyticsSettingsFailureType
  | FetchAnalyticsSettingsRequestType
  | FetchAnalyticsSettingsSuccessType
  | UpdateAnalyticsSettingSelectedValueType
  | ResetAnalytsicsSettingsTODefaults
  | ClearStoreType

export type FetchAnalyticsSettingsFailureType = {
  type: AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_FAILURE
  payload: { error: string }
}

export type FetchAnalyticsSettingsRequestType = {
  type: AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_REQUEST
  payload: { portfolioID: IdType }
}

export type FetchAnalyticsSettingsSuccessType = {
  type: AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_SUCCESS
  payload: { data: SettingType[] }
}

export type ResetAnalytsicsSettingsTODefaults = {
  type: AnalyticsSettingsActionEnum.RESET_ANALYTICS_SETTINGS_TO_DEFAULTS
}

export type UpdateAnalyticsSettingSelectedValueType = {
  type: AnalyticsSettingsActionEnum.UPDATE_ANALYTICS_SETTING_SELECTED_VALUE
  payload: {
    stateId: AnalyticsSettingEnum
    selected: SelectedAnalyticsSettingType
  }
}
