import { ICustomizationsStore } from "src/redux/types"
import { CustomizationTypesEnum } from "src/types/common"

const initialState: ICustomizationsStore = {
  allIds: Object.values(CustomizationTypesEnum),
  byId: {},
  pending: false,
  error: null,
}

export default initialState
