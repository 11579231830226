import { ISectionSettingsStore } from "src/redux/types"
import { PropType, SectionNameEnum } from "src/types/common"

const initialState: ISectionSettingsStore = {
  byId: Object.values(SectionNameEnum).reduce(
    (acc: PropType<ISectionSettingsStore, "byId">, curr) => {
      return (
        (acc[curr] = {
          id: SectionNameEnum[curr],
          configs: {},
          filters: {},
          splitFilterId: undefined,
          splitA: [],
          splitB: [],
        }),
        acc
      )
    },
    {}
  ),
  allIds: Object.values(SectionNameEnum),
}

export default initialState
