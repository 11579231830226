import { UserManagementService } from "@behaviour-lab/blab-component-library"
import { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from "axios"

export const addAuthorizationHeader = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const idToken = await UserManagementService.getIdToken()

  if (idToken) {
    config.headers["Authorization"] = `Bearer ${idToken}`
  }

  return config
}

const isTokenExpiredError = (error: AxiosError) =>
  error.response?.status === 401 || // analytics error on invalid token
  (error.response?.data as { message: string })?.message === "Invalid JWT Token" // CRUD app error on invalid token

const handleRefreshToken = async (
  error: AxiosError,
  axiosInstance: AxiosInstance
) => {
  const refreshedToken = await UserManagementService.refreshToken()

  if (!refreshedToken) return

  const originalRequest = error.config as InternalAxiosRequestConfig

  originalRequest.headers["Authorization"] = `Bearer ${refreshedToken}`

  return axiosInstance(originalRequest)
}

type RetryOptionsType = {
  retryTimes: number
  retryStatusCodes?: number[]
  notRetryStatusCodes?: number[]
}

const handleRetry = ({
  failedRequests,
  key,
  retryHandler,
  error,
  shouldRetry,
  retryTimes,
}: {
  failedRequests: { [key: string]: number }
  key: string
  retryHandler: () => unknown
  error: AxiosError
  shouldRetry: boolean
  retryTimes: number
}) => {
  if (!failedRequests[key]) {
    failedRequests[key] = 1
  }

  const currentCounter = failedRequests[key]
  const isMaxRetryTimesNotReached = currentCounter <= retryTimes

  if (isMaxRetryTimesNotReached && shouldRetry) {
    failedRequests[key] = currentCounter + 1

    return new Promise(resolve => {
      resolve(retryHandler())
    })
  } else {
    delete failedRequests[key]

    return Promise.reject(error)
  }
}

export const retryWrapper = (
  axiosInstance: AxiosInstance,
  options: RetryOptionsType
) => {
  const failedRequests = {}

  axiosInstance.interceptors.response.use(null, async (error: AxiosError) => {
    if (isTokenExpiredError(error)) {
      const key = `refresh-${error.config?.method}-${error.config?.url}`

      return await handleRetry({
        failedRequests,
        key,
        retryHandler: () => handleRefreshToken(error, axiosInstance),
        error,
        shouldRetry: true,
        retryTimes: options.retryTimes,
      })
    }

    const key = `${error.config?.method}-${error.config?.url}`

    const areStatusCodesForRetry =
      (!options.retryStatusCodes?.length &&
        !options.notRetryStatusCodes?.length) ||
      (!!options.retryStatusCodes?.length &&
        !!options.retryStatusCodes.includes(
          error.response?.status as number
        )) ||
      (!!options.notRetryStatusCodes?.length &&
        !options.notRetryStatusCodes.includes(error.response?.status as number))

    return await handleRetry({
      failedRequests,
      key,
      retryHandler: () =>
        axiosInstance(error.config as InternalAxiosRequestConfig),
      error,
      shouldRetry: areStatusCodesForRetry,
      retryTimes: options.retryTimes,
    })
  })
}
