import { STOCK_TABLE } from "src/components/Section/DataQuery/pluginResponseValidation/pluginResponseTypes"

export const sortStocksAlphabetical = (
  pluginResult: STOCK_TABLE
): STOCK_TABLE => {
  return {
    result: {
      ...pluginResult.result,
      sourceData: [...pluginResult.result.sourceData].sort((a, b) => {
        const nameA = a?.name?.toUpperCase()
        const nameB = b?.name?.toUpperCase()

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }

        return 0
      }),
    },
  }
}
