import { createSelector } from "reselect"

import {
  KeyMetricItemType,
  SplitRequestEnum,
  PluginNameEnum,
  InjectedParamsType,
} from "src/types/common"

import { AppState } from "../rootReducer"

export const getKeyMetricsErrorSelector = () =>
  createSelector(
    [
      (state: AppState) => state.sectionData.byId,
      (_: AppState, { keyMetricIds, reqType }) => ({
        stateIds: keyMetricIds.map(
          (keyMetric: KeyMetricItemType) =>
            (
              keyMetric as {
                stateId: PluginNameEnum
                injectedParams?: InjectedParamsType
              }
            )?.stateId || keyMetric
        ),
        reqType,
      }),
    ],
    (
      sectionDataById,
      {
        stateIds,
        reqType,
      }: { stateIds: PluginNameEnum[]; reqType: SplitRequestEnum }
    ) => stateIds.some(stateId => !!sectionDataById[stateId]?.[reqType]?.error)
  )

export const getKeyMetricsPendingSelector = () =>
  createSelector(
    [
      (state: AppState) => state.sectionData.byId,
      (_state: AppState, { keyMetricIds, reqType }) => {
        return {
          stateIds: keyMetricIds.map(
            (keyMetric: KeyMetricItemType) =>
              (
                keyMetric as {
                  stateId: PluginNameEnum
                  injectedParams?: { [paramName: string]: string | number }
                }
              )?.stateId || keyMetric
          ),
          reqType,
        }
      },
    ],
    (
      sectionDataById,
      {
        stateIds,
        reqType,
      }: { stateIds: PluginNameEnum[]; reqType: SplitRequestEnum }
    ) => stateIds.some(stateId => sectionDataById[stateId]?.[reqType]?.pending)
  )

export const getSectionDataErrorSelector = () =>
  createSelector(
    [
      (state: AppState) => state.sectionData.byId,
      (_: AppState, { stateId, reqType }) => ({ stateId, reqType }),
    ],
    (
      sectionDataById,
      {
        stateId,
        reqType,
      }: { stateId: PluginNameEnum; reqType: SplitRequestEnum }
    ) => sectionDataById[stateId]?.[reqType]?.error
  )

export const getSectionDataPendingSelector = () =>
  createSelector(
    [
      (state: AppState) => state.sectionData.byId,
      (_state: AppState, { stateId, reqType }) => ({ stateId, reqType }),
    ],
    (
      byId,
      {
        stateId,
        reqType,
      }: { stateId: PluginNameEnum; reqType: SplitRequestEnum }
    ) => !!byId[stateId]?.[reqType]?.pending
  )

export const getSectionDataSelector = () =>
  createSelector(
    [
      (state: AppState) => state.sectionData.byId,
      (_state: AppState, { stateId, reqType }) => ({ stateId, reqType }),
    ],
    (
      sectionData,
      {
        stateId,
        reqType,
      }: { stateId: PluginNameEnum; reqType: SplitRequestEnum }
    ) => sectionData[stateId]?.[reqType].data
  )

export const isSectionDataFetchEnabledSelector = createSelector(
  (state: AppState) => state.sectionData.isSectionDataFetchEnabled,
  isSectionDataFetchEnabled => isSectionDataFetchEnabled
)

export const requestsHistorySelector = createSelector(
  (state: AppState) => state.sectionData.requestsHistory,
  history => history
)
