import { PropType } from "src/types/common"

import {
  CustomizationsActionEnum,
  FetchCustomizationFailureType,
  FetchCustomizationRequestType,
  FetchCustomizationSuccessType,
  UpdateCustomizationInStoreType,
} from "./types"

export const fetchCustomizationError = (
  payload: PropType<FetchCustomizationFailureType, "payload">
): FetchCustomizationFailureType => ({
  type: CustomizationsActionEnum.FETCH_CUSTOMIZATION_FAILURE,
  payload,
})

export const fetchCustomizationRequest = (
  payload: PropType<FetchCustomizationRequestType, "payload">
): FetchCustomizationRequestType => ({
  type: CustomizationsActionEnum.FETCH_CUSTOMIZATION_REQUEST,
  payload,
})

export const fetchCustomizationSuccess = (
  payload: PropType<FetchCustomizationSuccessType, "payload">
): FetchCustomizationSuccessType => ({
  type: CustomizationsActionEnum.FETCH_CUSTOMIZATION_SUCCESS,
  payload,
})

export const updateCustomizationByType = (
  payload: PropType<UpdateCustomizationInStoreType, "payload">
): UpdateCustomizationInStoreType => ({
  type: CustomizationsActionEnum.UPDATE_CUSTOMIZATION_IN_STORE,
  payload,
})
