import { PropType } from "src/types/common"

import {
  ClientSettingsActionEnum,
  FetchClientRequestType,
  FetchClientSuccessType,
  FetchClientFailureType,
  FetchDataForInternalPagesType,
  UpdateActivePortfolioType,
} from "./types"

export const fetchClientsFailure = (
  payload: PropType<FetchClientFailureType, "payload">
): FetchClientFailureType => ({
  type: ClientSettingsActionEnum.FETCH_CLIENTS_FAILURE,
  payload,
})

export const fetchClientsRequest = (): FetchClientRequestType => ({
  type: ClientSettingsActionEnum.FETCH_CLIENTS_REQUEST,
})

export const fetchClientsSuccess = (
  payload: PropType<FetchClientSuccessType, "payload">
): FetchClientSuccessType => ({
  type: ClientSettingsActionEnum.FETCH_CLIENTS_SUCCESS,
  payload,
})

export const fetchDataForInternalPages = (
  payload: PropType<FetchDataForInternalPagesType, "payload">
): FetchDataForInternalPagesType => ({
  type: ClientSettingsActionEnum.FETCH_DATA_FOR_INTERNAL_PAGES,
  payload,
})

export const updateActivePortfolio = (
  payload: PropType<UpdateActivePortfolioType, "payload">
): UpdateActivePortfolioType => ({
  type: ClientSettingsActionEnum.UPDATE_ACTIVE_PORTFOLIO,
  payload,
})
