import { IUIStore } from "src/redux/types"

const initialState: IUIStore = {
  internalSidebar: {
    collapsed: false,
  },
  externalSidebar: {
    collapsed: false,
  },
  openedSidebarMenu: {},
}

export default initialState
