import {
  FilterNameEnum,
  SectionNameEnum,
  SplitRequestEnum,
  ConfigNameEnum,
  AppliedSettingsType,
} from "src/types/common"

import { ClearStoreType } from "../UI/types"

export type ClearSplitAndCompareType = {
  type: SectionSettingsActionEnum.CLEAR_SPLIT_AND_COMPARE
  payload: {
    sectionId: SectionNameEnum
    reqType: SplitRequestEnum
  }
}

export type ConfigsStoreType = { [configName in ConfigNameEnum]?: string }

export type DisableSplitAndCompareType = {
  type: SectionSettingsActionEnum.DISABLE_SPLIT_AND_COMPARE
  payload: {
    sectionId: SectionNameEnum
  }
}

export type EnableSplitAndCompareType = {
  type: SectionSettingsActionEnum.ENABLE_SPLIT_AND_COMPARE
  payload: {
    sectionId: SectionNameEnum
    filterId: FilterNameEnum
  }
}

export type FiltersStoreType = { [filterName in FilterNameEnum]?: string[] }

export type InitSectionConfigsSelectedValuesType = {
  type: SectionSettingsActionEnum.INIT_SECTION_CONFIGS_SELECTED_VALUES
  payload: {
    sectionId: SectionNameEnum
    data: AppliedSettingsType
  }
}

export enum SectionSettingsActionEnum {
  CLEAR_SPLIT_AND_COMPARE = "CLEAR_SPLIT_AND_COMPARE",
  DISABLE_SPLIT_AND_COMPARE = "DISABLE_SPLIT_AND_COMPARE",
  ENABLE_SPLIT_AND_COMPARE = "ENABLE_SPLIT_AND_COMPARE",
  INIT_SECTION_CONFIGS_SELECTED_VALUES = "INIT_SECTION_CONFIGS_SELECTED_VALUES",
  UPDATE_SECTION_CONFIGS_SELECTED_VALUES = "UPDATE_SECTION_CONFIGS_SELECTED_VALUES",
  UPDATE_SECTION_FILTER_SELECTED_VALUES = "UPDATE_SECTION_FILTER_SELECTED_VALUES",
  UPDATE_SPLIT_AND_COMPARE = "UPDATE_SPLIT_AND_COMPARE",
}

export type SectionSettingsActionType =
  | ClearSplitAndCompareType
  | DisableSplitAndCompareType
  | EnableSplitAndCompareType
  | InitSectionConfigsSelectedValuesType
  | UpdateSectionConfigsSelectedValuesType
  | UpdateSectionFilterSelectedValuesType
  | UpdateSplitAndCompareType
  | ClearStoreType

export type SectionSettingsStoreType = {
  id: SectionNameEnum
  configs: ConfigsStoreType
  filters: FiltersStoreType
  splitFilterId: FilterNameEnum | undefined
  splitA: string[]
  splitB: string[]
}

export type UpdateSectionConfigsSelectedValuesType = {
  type: SectionSettingsActionEnum.UPDATE_SECTION_CONFIGS_SELECTED_VALUES
  payload: {
    sectionId: SectionNameEnum
    data: AppliedSettingsType
  }
}

export type UpdateSectionFilterSelectedValuesType = {
  type: SectionSettingsActionEnum.UPDATE_SECTION_FILTER_SELECTED_VALUES
  payload: {
    sectionId: SectionNameEnum
    data: AppliedSettingsType
  }
}

export type UpdateSplitAndCompareType = {
  type: SectionSettingsActionEnum.UPDATE_SPLIT_AND_COMPARE
  payload: {
    sectionId: SectionNameEnum
    reqType: SplitRequestEnum
    splitValue: string[] // We have a lot more types of filters that can be split and compared, so in future the type can be extended
  }
}
