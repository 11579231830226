import {
  ICustomizationsStore,
  UIActionEnum,
  CustomizationsActionEnum,
  CustomizationsActionType,
} from "src/redux/types"
import { CustomizationType, CustomizationTypesEnum } from "src/types/common"

import initialState from "./initialState"

const userCustomizationsReducer = (
  state = initialState,
  action: CustomizationsActionType
): ICustomizationsStore => {
  switch (action.type) {
    case CustomizationsActionEnum.FETCH_CUSTOMIZATION_REQUEST: {
      return {
        ...state,
        pending: true,
      }
    }

    case CustomizationsActionEnum.FETCH_CUSTOMIZATION_SUCCESS: {
      const result = action.payload?.data || []

      return {
        ...state,
        byId: state.allIds.reduce(
          (acc, customizationType: CustomizationTypesEnum) => {
            const customization = result.find(
              (res: CustomizationType) => res.type === customizationType
            )
            if (customization) {
              acc[customizationType] = customization
            }

            return acc
          },
          {} as Record<CustomizationTypesEnum, CustomizationType>
        ),
        pending: false,
      }
    }

    case CustomizationsActionEnum.UPDATE_CUSTOMIZATION_IN_STORE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.type]: action.payload.updatedData,
        },
      }
    }

    case CustomizationsActionEnum.FETCH_CUSTOMIZATION_FAILURE: {
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      }
    }

    case UIActionEnum.CLEAR_STORE:
      return initialState

    default:
      return {
        ...state,
      }
  }
}

export default userCustomizationsReducer
