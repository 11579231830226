import {
  ISectionDataStore,
  UIActionEnum,
  SectionDataActionType,
  SectionDataActionEnum,
} from "src/redux/types"

import initialState from "./initialState"

const SectionDataReducer = (
  state = initialState,
  action: SectionDataActionType
): ISectionDataStore => {
  switch (action.type) {
    case SectionDataActionEnum.FETCH_SECTION_DATA_REQUEST:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.stateId]: {
            ...state.byId[action.payload.stateId],
            [action.payload.reqType]: {
              ...state.byId[action.payload.stateId]?.[action.payload.reqType],
              pending: true,
              error: null,
            },
          },
        },
      }
    case SectionDataActionEnum.FETCH_SECTION_DATA_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.stateId]: {
            ...state.byId[action.payload.stateId],
            [action.payload.reqType]: {
              ...state.byId[action.payload.stateId]?.[action.payload.reqType],
              data: action.payload.data,
              pending: false,
              error: null,
            },
          },
        },
        requestsHistory: {
          ...state.requestsHistory,
          [action.payload.stateId]: {
            ...(state.requestsHistory[action.payload.stateId] || {}),
            [action.payload.requestKey]: action.payload.data,
          },
        },
      }
    }

    case SectionDataActionEnum.FETCH_SECTION_DATA_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.stateId]: {
            ...state.byId[action.payload.stateId],
            [action.payload.reqType]: {
              ...state.byId[action.payload.stateId]?.[action.payload.reqType],
              pending: false,
              error: action.payload.error,
            },
          },
        },
      }

    case SectionDataActionEnum.IS_SECTION_DATA_FETCH_ENABLED:
      return {
        ...state,
        isSectionDataFetchEnabled: action.payload,
      }

    case UIActionEnum.CLEAR_STORE:
      return initialState

    default:
      return {
        ...state,
      }
  }
}

export default SectionDataReducer
