import {
  IdType,
  CustomizationTypesEnum,
  CustomizationType,
} from "src/types/common"
import { crudAxios as axios } from "src/utils/axios"

import { ResponseWrapper } from "./types"

export const createStockTableColumnsCustomization = (
  payload: Partial<CustomizationType>
) =>
  axios.post<ResponseWrapper<CustomizationType>>("/customization", {
    ...payload,
    type: CustomizationTypesEnum.STOCK_TABLE_COLUMNS,
  })

export const getStockTableColumnsCustomization = (portfolioID: IdType) =>
  axios.get<ResponseWrapper<CustomizationType[]>>(
    `/customization?type=${CustomizationTypesEnum.STOCK_TABLE_COLUMNS}&portfolioId=${portfolioID}`
  )

export const updateStockTableColumnsCustomization = (
  customizationId: IdType,
  payload: Partial<CustomizationType>
) =>
  axios.put<ResponseWrapper<CustomizationType>>(
    `/customization/${customizationId}`,
    {
      ...payload,
      type: CustomizationTypesEnum.STOCK_TABLE_COLUMNS,
    }
  )
