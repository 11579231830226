import { SectionDataByIdType, ISectionDataStore } from "src/redux/types"
import { PluginNameEnum } from "src/types/common"

const initialState: ISectionDataStore = {
  byId: Object.values(PluginNameEnum).reduce((acc, curr) => {
    return (
      (acc[curr] = {
        standard: {
          data: {},
          pending: true,
          error: null,
        },
        splitA: {
          data: {},
          pending: true,
          error: null,
        },
        splitB: {
          data: {},
          pending: true,
          error: null,
        },
      }),
      acc
    )
  }, {} as SectionDataByIdType),
  allIds: Object.values(PluginNameEnum),
  isSectionDataFetchEnabled: false, // before fetch section data we need first fetch client and analyticsSettings
  requestsHistory: {},
}

export default initialState
