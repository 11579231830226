import { IClientSettingsStore } from "src/redux/types"

const initialState: IClientSettingsStore = {
  clientID: null,
  portfolioID: null,
  clients: [],
  portfolios: [],
  pending: true,
  error: null,
}

export default initialState
