import { PropType } from "src/types/common"

import {
  AnalyticsSettingsActionEnum,
  FetchAnalyticsSettingsFailureType,
  FetchAnalyticsSettingsRequestType,
  FetchAnalyticsSettingsSuccessType,
  UpdateAnalyticsSettingSelectedValueType,
} from "./types"

export const fetchAnalyticsSettingsFailure = (
  payload: PropType<FetchAnalyticsSettingsFailureType, "payload">
): FetchAnalyticsSettingsFailureType => ({
  type: AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_FAILURE,
  payload,
})

export const fetchAnalyticsSettingsRequest = (
  payload: PropType<FetchAnalyticsSettingsRequestType, "payload">
): FetchAnalyticsSettingsRequestType => ({
  type: AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_REQUEST,
  payload,
})

export const fetchAnalyticsSettingsSuccess = (
  payload: PropType<FetchAnalyticsSettingsSuccessType, "payload">
): FetchAnalyticsSettingsSuccessType => ({
  type: AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_SUCCESS,
  payload,
})

export const resetAnalyticsSettingToDefaults = () => ({
  type: AnalyticsSettingsActionEnum.RESET_ANALYTICS_SETTINGS_TO_DEFAULTS,
})

export const updateAnalyticsSettingSelectedValue = (
  payload: PropType<UpdateAnalyticsSettingSelectedValueType, "payload">
): UpdateAnalyticsSettingSelectedValueType => ({
  type: AnalyticsSettingsActionEnum.UPDATE_ANALYTICS_SETTING_SELECTED_VALUE,
  payload,
})
