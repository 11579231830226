import { AxiosResponse } from "axios"
import { all, call, put, takeEvery } from "redux-saga/effects"

import { ResponseWrapper, getAnalyticsSettings } from "src/api"
import { SettingType } from "src/types"

import {
  fetchAnalyticsSettingsFailure,
  fetchAnalyticsSettingsSuccess,
} from "./actions"
import {
  AnalyticsSettingsActionEnum,
  FetchAnalyticsSettingsRequestType,
} from "./types"

/**
 * Worker Saga: Fired on `FETCH_ANALYTICS_SETTINGS_REQUEST` action
 */
function* fetchAnalyticsSettingsSaga(
  action: FetchAnalyticsSettingsRequestType
) {
  try {
    const response: AxiosResponse<ResponseWrapper<SettingType[]>> = yield call(
      getAnalyticsSettings,
      action.payload.portfolioID
    )

    yield put(fetchAnalyticsSettingsSuccess(response.data))
  } catch (e) {
    yield put(
      fetchAnalyticsSettingsFailure({
        error: (e as Error).message,
      })
    )
  }
}

/**
 * Starts worker saga on every dispatched `FETCH_ANALYTICS_SETTINGS_REQUEST` action.
 * Allows concurrent updates
 * TODO: @YANNIS should this realy allow concurrent updates?
 */
function* analyticsSettingsSaga() {
  yield all([
    takeEvery(
      AnalyticsSettingsActionEnum.FETCH_ANALYTICS_SETTINGS_REQUEST,
      fetchAnalyticsSettingsSaga
    ),
  ])
}

export default analyticsSettingsSaga
