export type ClearStoreType = {
  type: UIActionEnum.CLEAR_STORE
}

export type ToggleExternalSidebarType = {
  type: UIActionEnum.TOGGLE_EXTERNAL_SIDEBAR
}

export type ToggleInternalSidebarType = {
  type: UIActionEnum.TOGGLE_INTERNAL_SIDEBAR
}

export enum UIActionEnum {
  CLEAR_STORE = "CLEAR_STORE",
  TOGGLE_EXTERNAL_SIDEBAR = "TOGGLE_EXTERNAL_SIDEBAR",
  TOGGLE_INTERNAL_SIDEBAR = "TOGGLE_INTERNAL_SIDEBAR",
  UPDATE_SIDEBAR_STATUS = "UPDATE_SIDEBAR_STATUS",
}

export type UIActionType =
  | ClearStoreType
  | ToggleExternalSidebarType
  | ToggleInternalSidebarType
  | UpdateSidebarStatusType

export type UpdateSidebarStatusType = {
  type: UIActionEnum.UPDATE_SIDEBAR_STATUS
  payload: string
}
