import { UIActionEnum, UIActionType, IUIStore } from "src/redux/types"

import initialState from "./initialState"

const UIReducer = (state = initialState, action: UIActionType): IUIStore => {
  switch (action.type) {
    case UIActionEnum.TOGGLE_INTERNAL_SIDEBAR:
      return {
        ...state,
        internalSidebar: {
          ...state.internalSidebar,
          collapsed: !state.internalSidebar.collapsed,
        },
      }

    case UIActionEnum.TOGGLE_EXTERNAL_SIDEBAR:
      return {
        ...state,
        externalSidebar: {
          ...state.externalSidebar,
          collapsed: !state.externalSidebar.collapsed,
        },
      }

    case UIActionEnum.UPDATE_SIDEBAR_STATUS: {
      return {
        ...state,
        openedSidebarMenu: {
          ...state.openedSidebarMenu,
          [action.payload]: !state.openedSidebarMenu[action.payload],
        },
      }
    }

    case UIActionEnum.CLEAR_STORE:
      return initialState

    default:
      return {
        ...state,
      }
  }
}

export default UIReducer
