import { SettingType, ParamNameEnum } from "src/types/common"

const getSelectedAnalyticsSettingByDefault = (settingData: SettingType) => {
  if (!settingData) return

  // TODO: Setting the value of the "Buy Selection Horizon" to -1 by advisory request.
  // Fix the main fund configuration that doesn't allow you to do this manually
  if (settingData.name === ParamNameEnum.BUY_SELECTION_HORIZON) {
    return "-1"
  }

  return settingData.options?.filter(option => option.default === true)[0]
    ?.value
}

export default getSelectedAnalyticsSettingByDefault
