import { ClientType, IdType } from "src/types/common"

import { ClearStoreType } from "../UI/types"

export enum ClientSettingsActionEnum {
  FETCH_CLIENTS_FAILURE = "FETCH_CLIENTS_FAILURE",
  FETCH_CLIENTS_REQUEST = "FETCH_CLIENTS_REQUEST",
  FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS",
  FETCH_DATA_FOR_INTERNAL_PAGES = "FETCH_DATA_FOR_INTERNAL_PAGES",
  UPDATE_ACTIVE_PORTFOLIO = "UPDATE_ACTIVE_PORTFOLIO",
}

export type ClientSettingsActionType =
  | FetchClientRequestType
  | FetchClientSuccessType
  | FetchClientFailureType
  | UpdateActivePortfolioType
  | ClearStoreType

export type FetchClientFailureType = {
  type: ClientSettingsActionEnum.FETCH_CLIENTS_FAILURE
  payload: { error: string }
}

export type FetchClientRequestType = {
  type: ClientSettingsActionEnum.FETCH_CLIENTS_REQUEST
}

export type FetchClientSuccessType = {
  type: ClientSettingsActionEnum.FETCH_CLIENTS_SUCCESS
  payload: {
    data: ClientType[]
  }
}

export type FetchDataForInternalPagesType = {
  type: ClientSettingsActionEnum.FETCH_DATA_FOR_INTERNAL_PAGES
  payload: {
    portfolioID: IdType
  }
}

export type UpdateActivePortfolioType = {
  type: ClientSettingsActionEnum.UPDATE_ACTIVE_PORTFOLIO
  payload: { clientID: string; portfolioID: string }
}
